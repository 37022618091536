@use "sass:math";

@mixin sine-wave($amplitude: 35%, $frequency: 3, $y-offset: 1%, $x-offset: 25%, $steps: 200,$invert: false) {
  $points: 0 0, 0 100%;
  @if ($invert) {
    $points: 0 100%, 0 0;
  }

  // Loop through each step to calculate the sine wave points
  @for $i from 0 through $steps {
    $x: math.div(100%, $steps) * $i;
    // Calculate the phase shift based on x-offset
    $phase: math.div($x-offset * 360deg, 100%);
    $angle: math.div($i * 360deg, $steps) * $frequency - $phase;

    // Calculate the y-coordinate with y-offset
    $y: 50% + $y-offset - ($amplitude * 0.5 * sin($angle));

    // Append each calculated point to the polygon path
    $points: #{$points}, #{$x} #{$y};
  }

  // Close the polygon path with bottom-right and top-right corners
  @if ($invert) {
    $points: #{$points}, 100% 100%;
  } @else {
    $points: #{$points}, 100% 100%, 100% 0;
  }


  // Apply the generated polygon points to the clip-path
  --webkit-clip-path: polygon($points);
  clip-path: polygon($points);
  transform: translateZ(0); //creates a clean edge
}
