@use "sass:color";

$backdrop: #1b1b1b;
$enable-negative-margins: true;

// Configuration
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// Layout & components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
//@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
//@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
//@import "bootstrap/scss/dropdown";
//@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
//@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
//@import "bootstrap/scss/breadcrumb";
//@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
//@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
//@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
//@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
//@import "bootstrap/scss/offcanvas";
//@import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";

@import "functions";

@font-face {
  font-family: 'Alatsi';
  src: url('/assets/fnt/Alatsi-Regular.woff2') format('woff2'),
  url('/assets/fnt/Alatsi-Regular.woff') format('woff'),
  url('/assets/fnt/Alatsi-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  height: 100%;
  min-height: 100%;
  position: relative;
}

body {
  background: $backdrop;
  height: 100%;
  width: 100%;
}

.content {
  z-index: 1;
}


.bg-cont {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  overflow: hidden;
  z-index: -100;

  .wave {
    width: 100%;
    height: 250px;
    min-width: 1000px;

    position: absolute;
    @include sine-wave($amplitude: 35%, $frequency: 2.5, $y-offset: 30%, $x-offset: +25%, $steps: 250, $invert: false);

    .background {
      background: linear-gradient(130deg, #3e76ff, #00cae4 41.07%, #73ff79 76.05%);
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
}


h1 {
  font-family: 'Alatsi', 'Tahoma', sans-serif;
  color: #262626;

  :hover {

    color: color.adjust(#262626, $lightness: 15%) !important;

  }

  .blink_me {
    animation: blinker 1s linear infinite;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }

    @keyframes blinker {
      50% {
        opacity: 0;
      }
    }

  }
}

#server-list .card {
  //border: 0;
  box-shadow: rgba(0, 0, 0, 0.4) 0 2px 4px, rgba(0, 0, 0, 0.3) 0 7px 13px -3px, rgba(0, 0, 0, 0.2) 0 -3px inset;

  .card-img-top {
    height: 100px;
    object-fit: cover;
  }

  &.offline .card-img-top {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }

  .cutout {
    position: absolute;
    padding: 1.25rem 1.25rem 1.25rem 0;

    > * {
      background-color: rgba(255, 255, 255, 0.80);
      color: black;
      mix-blend-mode: screen; /* This makes the cutout text possible */
      position: relative;
      float: left;
      padding: 0.25rem 1.25rem;
      border-radius: 0 0.25rem 0.25rem 0;
    }
  }
}
